import Vue from 'vue'
import VueRouter from 'vue-router'
import MainView from '../views/MainView.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'mian',
    component: MainView,
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('views/HomeView')
      },
      {
        path: '/surrounding-facilities',
        name: 'surrounding-facilities',
        component: () => import('views/SurroundingView')
      },
      {
        path: '/traffic-instructions',
        name: 'traffic-instructions',
        component: () => import('views/TrafficView')
      },
      {
        path: '/land',
        name: 'land',
        // component: () => import('views/LandView')
        component: () => import('views/Land1')
      },
      {
        path: '/contact',
        name: 'contact',
        component: () => import('views/ContactView')
      }
    ]
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/disclaimer',
    name: 'disclaimer',
    component: () => import('views/DisclaimerView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('views/Login.vue')
  },
  {
    path: '/land-main',
    name: 'land-main',
    component: () => import('views/platform/Platform.vue'),
    children: [
      {
        path: '/land-main',
        name: 'land-main',
        component: () => import('views/platform/LandList.vue')
      },
      {
        path: '/intended-user',
        name: 'intended-user',
        component: () => import('views/platform/intended/IntendedUser.vue')
      },
      {
        path: '/operate',
        name: 'operate',
        component: () => import('views/platform/Operate.vue')
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

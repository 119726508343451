<template>
  <div class="main">
    <!-- <NavBar /> -->
    <!-- <el-menu :default-active="activeIndex"
             v-model="activeIndex"
             router
             class="el-menu-main"
             mode="horizontal"
             background-color="#474c55"
             text-color="#fff"
             active-text-color="#ffd04b"
             @select="handleSelect"> -->
    <el-menu :default-active="activeIndex"
             v-model="activeIndex"
             router
             class="el-menu-main"
             mode="horizontal"
             background-color="#FFF"
             text-color="#333"
             active-text-color="#d81e06"
             @select="handleSelect">
      <div class="logo">
        <el-image class="logo-img"
                  :src="logoImage"></el-image>
      </div>
      <!-- <el-menu-item>
        <template slot="title">
          <span style="width: auto; height: auto; visibility: visible;">自定义图片</span>
        </template>
      </el-menu-item> -->
      <el-menu-item index="/"
                    class="item-home">WELCOME</el-menu-item>
      <el-menu-item index="/surrounding-facilities">SURROUNDING FACILITIES</el-menu-item>
      <el-menu-item index="/traffic-instructions">TRAFFIC INSTRUCTIONS</el-menu-item>
      <el-menu-item index="/land">LAND NOW SELLING</el-menu-item>
      <!-- <el-menu-item index="/land1">LAND</el-menu-item> -->
      <!-- <el-menu-item index="/masterplan">MASTER PLAN</el-menu-item> -->

      <!-- <el-menu-item index="/guided-by-design">GUIDED BY DESIGN</el-menu-item> -->
      <el-menu-item index="/contact">CONTACT</el-menu-item>
    </el-menu>
    <div class="main-header">
      <div class="main-header-open">
        <el-image class="main-header-open-img"
                  @click="openMuen"
                  :src="openImage"></el-image>
      </div>
      <div class="main-header-logo">
        <el-image class="main-header-logo-img"
                  :src="logoImage"></el-image>
      </div>

    </div>

    <el-menu :default-active="activeIndex"
             v-model="activeIndex"
             v-show="openFlag"
             router
             class="el-menu-main-vertical"
             background-color="#FFF"
             text-color="#333"
             active-text-color="#d81e06"
             @select="handleSelect">
      <el-menu-item index="/">WELCOME</el-menu-item>
      <el-menu-item index="/surrounding-facilities">SURROUNDING FACILITIES</el-menu-item>
      <el-menu-item index="/traffic-instructions">TRAFFIC INSTRUCTIONS</el-menu-item>
      <el-menu-item index="/land">LAND NOW SELLING</el-menu-item>
      <!-- <el-menu-item index="/land1">LAND</el-menu-item> -->
      <!-- <el-menu-item index="/masterplan">MASTER PLAN</el-menu-item> -->

      <!-- <el-menu-item index="/guided-by-design">GUIDED BY DESIGN</el-menu-item> -->
      <el-menu-item index="/contact">CONTACT</el-menu-item>
    </el-menu>

    <keep-alive>
      <router-view />
    </keep-alive>
  </div>
</template>

<script>
// @ is an alias to /src'

export default {
  name: 'HomeView',
  data () {
    return {
      activeIndex: '/',
      openFlag: false,
      // muenImage: require('@/assets/img/img-open.png'),
      openImage: require('@/assets/img/img-open.png'),
      // closeImage: require('@/assets/img/img-open.png'),
      logoImage: require('@/assets/img/logo.png'),
    };
  },
  components: {},

  created () {
    console.log(this.$router)
    this.activeIndex = this.$router.currentRoute.path

  },

  mounted () {
    this.$bus.$on('changeIndex', (activeIndex) => {  // 监听外部页面更新提示
      console.log(activeIndex)
      this.activeIndex = activeIndex
    })
    console.log(this.$router)
  },

  methods: {
    toPage () {
      this.openFlag = !this.openFlag
      if (this.$router.currentRoute.path != '/') {
        console.log(this.activeIndex)
        this.activeIndex = '/';
        console.log(this.activeIndex)
        this.$router.push('/');
      }

    },

    openMuen () {

      if (this.openFlag) {
        this.openImage = require('@/assets/img/img-open.png')
        // muenImage = this.openImage
      } else {
        this.openImage = require('@/assets/img/img-close.png')
        // muenImage = this.colseImage
      }

      this.openFlag = !this.openFlag



    },

    handleSelect (key, keyPath) {
      this.openImage = require('@/assets/img/img-open.png')
      this.openFlag = false
      this.activeIndex = key
      console.log(key, keyPath);
    }
  }

}
</script>
<style type="text/scss" lang="scss">
* {
  padding: 0;
  margin: 0;
}

* {
  -webkit-touch-callout: none; /* 系统默认菜单被禁用 */
  -webkit-user-select: none; /* webkit浏览器 */
  -khtml-user-select: none; /* 早期浏览器 */
  -moz-user-select: none; /* 火狐 */
  -ms-user-select: none; /* IE10 */
  user-select: none;
}
input,
textarea {
  /* webkit浏览器 */
  margin: 0px;
  padding: 0px;
  outline: none;
}
* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent; /* For some Androids */
}

// 更改鼠标划过背景色字体色及borer显示
.el-menu-item:not(.is-disabled):hover {
  // background: #d81e0633 !important;
  // color: #fff !important;
}

body {
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

.main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.el-menu-main {
  display: flex;
  justify-content: center;
  // border-bottom: none !important;
}

.main-header {
  height: 60px;
  width: 100%;
  background-color: #fff;
  display: none; /* 隐藏菜单默认状态 */
  border-bottom: solid 1px #e6e6e6;
}

.main-header-open {
  position: absolute;
  height: 60px;
  width: 80px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-header-open-img {
  height: 24px;
  width: 24px;
}

.el-menu-main-vertical {
  flex-direction: column;
  position: absolute;
  background-color: #333;
  width: 100% !important;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: none; /* 隐藏菜单默认状态 */
}

.main-header-logo {
  width: 155px;
  height: 60px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  .main-header-logo-img {
    width: 155px;
    height: 50px;
  }
}

.logo {
  position: absolute;
  top: 0;
  left: 15px;
  width: 155px;
  height: 60px;
  float: left;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  .logo-img {
    width: 155px;
    height: 50px;
  }
}

.item-home {
  margin-left: 200px !important;
}

.el-menu-item {
  border-bottom: none !important;
}

.el-menu-item {
  border-bottom: none !important;
}

/* 响应式设置 */
@media (max-width: 900px) {
  .el-menu-main {
    flex-direction: column;
    position: absolute;
    background-color: #333;
    width: 100%;
    left: 0;
    top: 0;
    display: none; /* 隐藏菜单默认状态 */
  }

  .main-header {
    display: flex;
    justify-content: center;
  }

  .el-menu-main-vertical {
    display: flex;
    border-bottom: none !important;
    width: 100% !important;
    height: 100%;
  }

  .menu-button {
    display: inline-block;
  }

  .menu[style*="display: block"] {
    display: block; /* 显示菜单 */
  }
}
</style>


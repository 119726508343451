import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';

import './assets/styles/element-variables.scss'

import './assets/styles/index.scss'

import locale from 'element-ui/lib/locale';
// import lang from 'element-ui/lib/locale/lang/zh-CN';
import enLocale from 'element-ui/lib/locale/lang/en'
// import zhLocale from 'element-ui/lib/locale/lang/zh-CN'

// 设置ElementUI的语言
locale.use(enLocale);


Vue.config.productionTip = false

Vue.use(ElementUI, { size: 'mini', zIndex: 3000 })

import filters from '@/filter';

Vue.use(filters)


// 国际化
window.Vue = Vue

Vue.prototype.$bus = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

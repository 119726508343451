
export const numberFormat = money => {
  if (money == 0 || money == '0') {
    return '0'
  } else {
    return parseInt(money);
  }
}


const searchList = [
  { text: '全部', value: '' },
  { text: '増值税专用发票', value: 'vat_special_invoice' },
  { text: '増值税普通发票', value: 'vat_common_invoice' },
  { text: '增值税电子专用发票', value: 'vat_electronic_special_invoice' },
  { text: '通用电子发票', value: 'vat_electronic_general_invoice' },
  { text: '增值税电子普通发票（通行费）', value: 'vat_electronic_toll_common_invoice' },
  { text: '增值税电子普通发票', value: 'vat_electronic_common_invoice' },
  { text: '增值税电子发票 (其他增值税电子发票统称)', value: 'vat_electronic_invoice' },
  { text: '増值税卷式发票', value: 'vat_roll_invoice' },
  { text: '车辆通行费', value: 'vehicle_toll_invoice' },
  { text: '汽车票', value: 'bus_ticket' },
  { text: '飞机行程单', value: 'flight_itinerary' },
  { text: '出租车发票', value: 'taxi_ticket' },
  { text: '火车票', value: 'train_ticket' },
  { text: '定额发票(包含停车票,地铁票, 手撕票等多种定额票据）', value: 'quota_invoice' },
  { text: '通用机打发票', value: 'general_machine_invoice' },
  { text: '二手车购车发票', value: 'used_car_purchase_invoice' },
  { text: '机动车购买发票', value: 'motor_vehicle_purchase_invoice' },
  { text: '其他', value: 'others' },
  { text: '全电发票', value: 'vat_electronic_full_digital_invoice' },
  { text: '全电票（纸质发票）', value: 'paper_electronic_full_invoice' }
];

export const invoiceTypeFun = value => {
  for (let i in searchList) {
    if (searchList[i].value == value) {
      return searchList[i].text
    }
  }
};



export const formattNumber = num => {
  return num.toLocaleString();
};

export default {
  install (Vue) {
    Vue.filter('formattNumber', formattNumber);
  }
};